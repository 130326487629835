<template>
  <div class="mt-1 mx-3">
    <agency-sectors> </agency-sectors>
  </div>
</template>

<script>
import AgencySectors from '@/components/profile/sectors/agency/index';

import { mapActions } from 'vuex';

export default {
  props: {
    msg: String,
  },
  methods: {
    ...mapActions("queue", ["notifyQueue"]),
  },
  components: {
    AgencySectors,
  },
  created() {
    this.notifyQueue({ text: "Comparti di agenzia", path: "sectors.index0", level: 1 });
  },
};
</script>
