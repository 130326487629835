<template>
  <div class="mt-1 mx-3">
    <agency-sectors-create> </agency-sectors-create>
  </div>
</template>

<script>
import AgencySectorsCreate from "@/components/profile/sectors/agency/create.vue";
import { mapActions } from "vuex";

export default {
  methods: {
    ...mapActions("queue", ["notifyQueue"]),
  },
  components: {
    AgencySectorsCreate,
  },
  created() {
    this.notifyQueue({
      text: "Comparti di agenzia",
      path: "module.PROF.COMP",
      tab: "AgencySectors",
      level: 3,
    });
    this.notifyQueue({
      text: "Crea Comparto di agenzia",
      path: "sectors.create0",
      level: 4,
    });
  },
};
</script>
